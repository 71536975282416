header {
    width: 100%;
}
.header {
    position: relative;
    height: var(--header-height);
    width: 100%;
    background-color: rgba(7, 7, 7, 0.35);
    /* background-color: rgb(24, 23, 23); */
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 1.5em;
    color: white;
    font-weight: 0;
}

.header-text {
    padding-left: 5%;
    margin-right: auto;
    color: white;
    font-size: 1.6em;
    /* padding-left: 4vmin; */
    /* padding-right: 8vmin; */
    /* height: 100%; */
    text-decoration: none;
    font-weight: 1000;

}

.header-link-active {
    /* background-color: rgb(17, 16, 16);     */
    transition: 400ms;
    display: flex;
    /* font-size: 6vmin; */
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(65, 64, 64, 0.3);
    height: 100%;
    text-decoration: none;
    font-weight: 700;
    /* background-color: rgb(37, 36, 36); */
}

.header-link {
    --header-link-padding: 6vw;
    transition: 400ms;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0 var(--header-link-padding) 0 var(--header-link-padding);
    height: 100%;
    text-decoration: none;
    
}

