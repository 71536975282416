@import url(https://fonts.googleapis.com/css?family=Cabin+Sketch&display=swap);
@import url(https://fonts.googleapis.com/css?family=Liu+Jian+Mao+Cao&display=swap);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(37, 36, 36); */
  /* background-color: rgb(19, 18, 18); */
  /* background-color: black; */
  
  /* transition: 2s; */

  overflow-x: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
    width: 100%;
}
.header {
    position: relative;
    height: var(--header-height);
    width: 100%;
    background-color: rgba(7, 7, 7, 0.35);
    /* background-color: rgb(24, 23, 23); */
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 1.5em;
    color: white;
    font-weight: 0;
}

.header-text {
    padding-left: 5%;
    margin-right: auto;
    color: white;
    font-size: 1.6em;
    /* padding-left: 4vmin; */
    /* padding-right: 8vmin; */
    /* height: 100%; */
    text-decoration: none;
    font-weight: 1000;

}

.header-link-active {
    /* background-color: rgb(17, 16, 16);     */
    -webkit-transition: 400ms;
    transition: 400ms;
    display: flex;
    /* font-size: 6vmin; */
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(65, 64, 64, 0.3);
    height: 100%;
    text-decoration: none;
    font-weight: 700;
    /* background-color: rgb(37, 36, 36); */
}

.header-link {
    --header-link-padding: 6vw;
    -webkit-transition: 400ms;
    transition: 400ms;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0 var(--header-link-padding) 0 var(--header-link-padding);
    height: 100%;
    text-decoration: none;
    
}



footer{
    width: 100%;
}
.footer {
    font-family: 'Cabin Sketch', sans-serif;
    height: var(--footer-height);
    width: 100%;
    position: relative;
    /* bottom:0; */
    background-color: rgba(7, 7, 7, 0.35);    
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    font-size: 2em;
    color: white;
}


.footer-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* padding-left: 4vmin;
    padding-right: 8vmin; */
    height: 100%;
    text-decoration: none;  
}

.socials {
    /* margin-left: 8vmin; */
    /* display:flex; */
    justify-content: center;
    align-content: center;
    align-items: center;
    /* padding: 3vmin; */
}

.social-link {
    width:3vmin;
    height:3vmin;
    color: white;
    padding-left: 3vmin;
    padding-right: 3vmin;
    /* border: 1px solid white;
    border-radius: 20px;  */
}



.socials-footer {
    /* margin-left: 1vmin; */
    display:flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* padding: 3vmin; */
}

.social-link-footer {
    width:5vmin;
    height:5vmin;
    color: white;
    padding-left: 4vmin;
    padding-right: 4vmin;
    /* border: 1px solid white;
    border-radius: 20px;  */
}
.container {
    display: flex;
    width: 100%;
    min-height: 30vmin;
    /* background-color: rgb(37, 36, 36); */
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
    overflow-x: hidden;
    /* flex-wrap: wrap; */
}

.container-horizontal {
    display: flex;
    width: 100%;
    min-height: 30vmin;
    /* background-color: rgb(37, 36, 36); */
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    overflow-x: hidden;

    /* flex-wrap: wrap; */

}

.container.videos {
    margin-top: 5vmin;
    flex-wrap: wrap;
}

.container.shop {
    flex-wrap: wrap;
}
.large {
    min-height:50vmin;
}


iframe {
    --video-width: 75vmin;
    --video-height: 50vmin;
    --video-max-width: 650px;
    --video-max-height: 400px;
    width: var(--video-width);
    height:  var(--video-height);
    max-width: var(--video-max-width);
    max-height: var(--video-max-height);
 box-shadow:  0px 0px 10px 6px rgb(15, 15, 15);
}

.video {
    align-self: flex-start;
    width: var(--video-width);
    height: var(--video-height); 
    opacity: 1; 
    justify-self: flex-start;
    -webkit-animation: zoomInVideo 1000ms ease-out;
            animation: zoomInVideo 1000ms ease-out;
    margin:2vmin;
}



@-webkit-keyframes zoomInVideo {
    from { width: var(--video-width); height: var(--video-height); opacity: 0.1; max-width: var(--video-max-width); max-height: var(--video-max-height);}
    to { width: var(--video-width); height: var(--video-height); opacity: 1; max-width: var(--video-max-width); max-height: var(--video-max-height);}
}



@keyframes zoomInVideo {
    from { width: var(--video-width); height: var(--video-height); opacity: 0.1; max-width: var(--video-max-width); max-height: var(--video-max-height);}
    to { width: var(--video-width); height: var(--video-height); opacity: 1; max-width: var(--video-max-width); max-height: var(--video-max-height);}
}

.show-all-videos {
    color: white;
    font-size: 2.2em;
    margin-bottom: 5vmin;
    cursor: pointer;
    width: auto;
    -webkit-transition: 500ms;
    transition: 500ms;
}

.show-all-videos:hover {
    -webkit-transition: 500ms;
    transition: 500ms;
    color: grey;
}




@media only screen and (max-width: 600px) {
    iframe {
        --video-width: 80vmin;
        --video-height: 50vmin;
        width: var(--video-width);
        height:  var(--video-height);
        max-width: var(--video-max-width);
        max-height: var(--video-max-height);
        box-shadow:  0px 0px 10px 6px rgb(15, 15, 15);
    }

    .video {
        width: var(--video-width);
        height: var(--video-height);
        margin: 2vmin 0 2vmin 0;
    }
}
:root {
  --header-height: 7vh;
  --footer-height: 8vh;
  --min-page-height: calc( 100vh - var(--header-height) - var(--footer-height));
}



.App {
   position: absolute;
   /* top:0;
   left:0; */
  background-color: rgba(8, 8, 8, 0.6);
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: 1em;
  color: black;
  /* font-family: 'Liu Jian Mao Cao', cursive; */
  font-family: 'Amatic SC', cursive;
  text-align: center;
  overflow-x: hidden;
  min-height: 100%;
  min-width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

@media only screen and (max-width: 600px) {
  .App {
    font-size: 0.75em;
  }
}


.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/static/media/black-large.cd7f34d5.jpeg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
}


.App-logo {
  height: 40vmin;
}

/* .App-header {
 
  
} */

.App-link {
  color: #09d3ac;
}

.page {
  position: relative;
  min-height: calc( 100vh - 7vh - 8vh);
  min-height: var(--min-page-height);
  width: 100%;
  /* background-color: rgb(37, 36, 36); */
  /* top: 10vmin;
  left: 0; */
  /* overflow-y:auto; */
}

.page-enter {
  opacity: 0;
  /* top: 20vw; */
  /* transform: scale(0.5); */
}

.page-enter-active {
  opacity: 1;
  /* top: 0; */
  /* transform: scale(1); */
  -webkit-transition: 1000ms;
  transition: 1000ms;
  
  /* transition: slide 300ms forwards; */
}

.page-exit {
  opacity: 1;
  /* top: 0vw; */
  -webkit-transform: scale(1);
          transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  /* top: -20vw; */
  /* transform: scale(0.5); */
  -webkit-transition: 1000ms;
  transition: 1000ms;
  /* transition: slide 300ms forwards; */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(46, 44, 44);
  border-radius: 30px;
  width: 10px;
}

::-webkit-scrollbar {
  background-color: rgb(0, 0, 0);
  /* border-radius: 30px; */
  width: 10px;
}
