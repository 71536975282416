@import url('https://fonts.googleapis.com/css?family=Liu+Jian+Mao+Cao&display=swap');
@import url('https://fonts.googleapis.com/css?family=Amatic+SC&display=swap');

:root {
  --header-height: 7vh;
  --footer-height: 8vh;
  --min-page-height: calc( 100vh - var(--header-height) - var(--footer-height));
}



.App {
   position: absolute;
   /* top:0;
   left:0; */
  background-color: rgba(8, 8, 8, 0.6);
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: 1em;
  color: black;
  /* font-family: 'Liu Jian Mao Cao', cursive; */
  font-family: 'Amatic SC', cursive;
  text-align: center;
  overflow-x: hidden;
  min-height: 100%;
  min-width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

@media only screen and (max-width: 600px) {
  .App {
    font-size: 0.75em;
  }
}


.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/black-large.jpeg') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
}


.App-logo {
  height: 40vmin;
}

/* .App-header {
 
  
} */

.App-link {
  color: #09d3ac;
}

.page {
  position: relative;
  min-height: var(--min-page-height);
  width: 100%;
  /* background-color: rgb(37, 36, 36); */
  /* top: 10vmin;
  left: 0; */
  /* overflow-y:auto; */
}

.page-enter {
  opacity: 0;
  /* top: 20vw; */
  /* transform: scale(0.5); */
}

.page-enter-active {
  opacity: 1;
  /* top: 0; */
  /* transform: scale(1); */
  transition: 1000ms;
  
  /* transition: slide 300ms forwards; */
}

.page-exit {
  opacity: 1;
  /* top: 0vw; */
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  /* top: -20vw; */
  /* transform: scale(0.5); */
  transition: 1000ms;
  /* transition: slide 300ms forwards; */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(46, 44, 44);
  border-radius: 30px;
  width: 10px;
}

::-webkit-scrollbar {
  background-color: rgb(0, 0, 0);
  /* border-radius: 30px; */
  width: 10px;
}