
@import url("https://fonts.googleapis.com/css?family=Cabin+Sketch&display=swap");

footer{
    width: 100%;
}
.footer {
    font-family: 'Cabin Sketch', sans-serif;
    height: var(--footer-height);
    width: 100%;
    position: relative;
    /* bottom:0; */
    background-color: rgba(7, 7, 7, 0.35);    
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    font-size: 2em;
    color: white;
}


.footer-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* padding-left: 4vmin;
    padding-right: 8vmin; */
    height: 100%;
    text-decoration: none;  
}
