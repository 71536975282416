.socials {
    /* margin-left: 8vmin; */
    /* display:flex; */
    justify-content: center;
    align-content: center;
    align-items: center;
    /* padding: 3vmin; */
}

.social-link {
    width:3vmin;
    height:3vmin;
    color: white;
    padding-left: 3vmin;
    padding-right: 3vmin;
    /* border: 1px solid white;
    border-radius: 20px;  */
}



.socials-footer {
    /* margin-left: 1vmin; */
    display:flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* padding: 3vmin; */
}

.social-link-footer {
    width:5vmin;
    height:5vmin;
    color: white;
    padding-left: 4vmin;
    padding-right: 4vmin;
    /* border: 1px solid white;
    border-radius: 20px;  */
}