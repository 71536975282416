.container {
    display: flex;
    width: 100%;
    min-height: 30vmin;
    /* background-color: rgb(37, 36, 36); */
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
    overflow-x: hidden;
    /* flex-wrap: wrap; */
}

.container-horizontal {
    display: flex;
    width: 100%;
    min-height: 30vmin;
    /* background-color: rgb(37, 36, 36); */
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    overflow-x: hidden;

    /* flex-wrap: wrap; */

}

.container.videos {
    margin-top: 5vmin;
    flex-wrap: wrap;
}

.container.shop {
    flex-wrap: wrap;
}
.large {
    min-height:50vmin;
}

