iframe {
    --video-width: 75vmin;
    --video-height: 50vmin;
    --video-max-width: 650px;
    --video-max-height: 400px;
    width: var(--video-width);
    height:  var(--video-height);
    max-width: var(--video-max-width);
    max-height: var(--video-max-height);
	-webkit-box-shadow: 0px 0px 10px 6px rgb(15, 15, 15);
    -moz-box-shadow: 0px 0px 10px 6px rgb(15, 15, 15);
    box-shadow:  0px 0px 10px 6px rgb(15, 15, 15);
}

.video {
    align-self: flex-start;
    width: var(--video-width);
    height: var(--video-height); 
    opacity: 1; 
    justify-self: flex-start;
    animation: zoomInVideo 1000ms ease-out;
    margin:2vmin;
}



@keyframes zoomInVideo {
    from { width: var(--video-width); height: var(--video-height); opacity: 0.1; max-width: var(--video-max-width); max-height: var(--video-max-height);}
    to { width: var(--video-width); height: var(--video-height); opacity: 1; max-width: var(--video-max-width); max-height: var(--video-max-height);}
}

.show-all-videos {
    color: white;
    font-size: 2.2em;
    margin-bottom: 5vmin;
    cursor: pointer;
    width: auto;
    transition: 500ms;
}

.show-all-videos:hover {
    transition: 500ms;
    color: grey;
}




@media only screen and (max-width: 600px) {
    iframe {
        --video-width: 80vmin;
        --video-height: 50vmin;
        width: var(--video-width);
        height:  var(--video-height);
        max-width: var(--video-max-width);
        max-height: var(--video-max-height);
        -webkit-box-shadow: 0px 0px 10px 6px rgb(15, 15, 15);
        -moz-box-shadow: 0px 0px 10px 6px rgb(15, 15, 15);
        box-shadow:  0px 0px 10px 6px rgb(15, 15, 15);
    }

    .video {
        width: var(--video-width);
        height: var(--video-height);
        margin: 2vmin 0 2vmin 0;
    }
}